import * as React from 'react'
import { PositionsTable, PositionsTableProps } from './positions-table'
import { TOrg } from 'src/design-system'

export type TemplatePageProps = {
  name: string
  org: TOrg
} & PositionsTableProps

export const TemplatePage = (props: TemplatePageProps) => {
  const { name, disciplines, org } = props

  return (
    <>
      <header className="lg:px-14 xl:px-20 pt-10">
        <p className="text-gray-600 text-sm">You&apos;ve chosen</p>
        <h2 className="font-bold font-condensed text-[48px] leading-[54px] mb-1">
          {name} Template
        </h2>
        <p className="mb-8 text-gray-600">
          By{' '}
          {org.avatarUrl && (
            <img
              src={org.avatarUrl}
              className="rounded-full bg-white h-5 w-5 mx-2"
              alt={`Avatar for ${org.name}`}
            />
          )}
          {org.name}
        </p>
      </header>

      <div className="flex-1 flex justify-center xl:pl-6 overflow-hidden pb-10 pt-24">
        <PositionsTable disciplines={disciplines} />
      </div>
    </>
  )
}
