export const debounce = (
  callback: () => void | Promise<void>,
  wait: number
): ((args: unknown) => void) => {
  let timeoutId: null | number = null
  return (...args: any) => {
    if (timeoutId) window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args)
    }, wait)
  }
}
