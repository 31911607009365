import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { debounce } from '../utils/debounce'

export type WithWorkEmailErrorProps = {
  children: React.ReactNode
}

const validateWorkEmail = async (email: string): Promise<boolean> => {
  /**
   * email regex
   * at least one character up until @ sign
   * at least one character after the @ sign until a .
   * at least 2 characters after the . for domain
   */
  const emailRegex = new RegExp(/.+@.+\..{2,}/)
  if (emailRegex.test(email)) {
    const response = await fetch(
      `/api/v1/internal/valid_work_domain?email=${email}`
    )

    const {
      data: { is_valid },
    } = await response.json()

    return is_valid
  } else {
    return true
  }
}

export const WithWorkEmailError: React.VFC<WithWorkEmailErrorProps> = (
  props
) => {
  const { children } = props

  const [showError, setShowError] = React.useState(false)

  React.useEffect(() => {
    const input = wrapperRef.current?.querySelector('input')

    if (!input) throw new Error("Input couldn't be found")

    const listener = debounce(async () => {
      const isValid = await validateWorkEmail(input.value)

      setShowError(!isValid || false)
    }, 200)

    input.addEventListener('keydown', listener)

    return () => {
      input.removeEventListener('keydown', listener)
    }
  }, [children])

  const wrapperRef = React.useRef<HTMLDivElement | null>(null)

  return (
    <div ref={wrapperRef}>
      {children}
      <AnimatePresence initial={false}>
        {showError && (
          <motion.p
            className="text-xs text-danger mt-2"
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
            exit={{ height: 0 }}
          >
            Progression works better when you try it with co-workers. Adding
            your work email will make it easier to invite others later.
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  )
}
