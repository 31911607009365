import * as React from 'react'
import {
  PositionsTable as BasePositionsTable,
  TDiscipline,
} from 'src/design-system'

export type PositionsTableProps = {
  disciplines: TDiscipline[]
}

export const PositionsTable = (props: PositionsTableProps) => {
  const { disciplines } = props

  return (
    <BasePositionsTable
      className="overflow-hidden pointer-events-none"
      disciplines={disciplines}
    />
  )
}
